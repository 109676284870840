var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"851251ac404c6c90465a0937f55492fec724263e"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

Sentry.init({
	debug: false,
	dsn: 'https://e93394bd69d240ff8109475f020ee9cb@o216501.ingest.sentry.io/4505054459658240',
	environment: process.env.VERCEL_ENV,
	tracesSampleRate: 1,
})
